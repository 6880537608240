import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-user-property-iconized',
  templateUrl: './user-property-iconized.component.html',
  styleUrls: ['./user-property-iconized.component.css']
})
export class UserPropertyIconizedComponent {

  constructor() { }
}
