<div class="row">
    <div class="col-sm-3">
        <div style="height: 200px; margin-bottom: 50px;">
            <ngx-shimmer-loading [borderRadius]="'5px'" [shape]="'square'" [width]="'100%'" [height]="'100%'"></ngx-shimmer-loading>
        </div>
        
        <div style="height: 50px; margin-bottom: 50px;">
            <ngx-shimmer-loading [borderRadius]="'5px'" [shape]="'square'" [width]="'100%'" [height]="'100%'"></ngx-shimmer-loading>
        </div>

        <div style="height: 25px; margin-bottom: 25px;">
            <ngx-shimmer-loading [borderRadius]="'5px'" [shape]="'square'" [width]="'100%'" [height]="'100%'"></ngx-shimmer-loading>
        </div>

        <div style="height: 25px; margin-bottom: 25px;">
            <ngx-shimmer-loading [borderRadius]="'5px'" [shape]="'square'" [width]="'100%'" [height]="'100%'"></ngx-shimmer-loading>
        </div>

        <div style="height: 25px; margin-bottom: 25px;">
            <ngx-shimmer-loading [borderRadius]="'5px'" [shape]="'square'" [width]="'100%'" [height]="'100%'"></ngx-shimmer-loading>
        </div>
    </div>

    <div class="col-sm-9">
        <div style="height: 100px; margin-bottom: 25px;">
            <ngx-shimmer-loading [borderRadius]="'5px'" [shape]="'square'" [width]="'100%'" [height]="'100%'"></ngx-shimmer-loading>
        </div>

        <div style="height: 50px; margin-bottom: 10px;">
            <ngx-shimmer-loading [borderRadius]="'5px'" [shape]="'square'" [width]="'100%'" [height]="'100%'"></ngx-shimmer-loading>
        </div>

        <div style="height: 50px; margin-bottom: 50px;">
            <ngx-shimmer-loading [borderRadius]="'5px'" [shape]="'square'" [width]="'100%'" [height]="'100%'"></ngx-shimmer-loading>
        </div>

        <div style="height: 100px; margin-bottom: 25px;">
            <ngx-shimmer-loading [borderRadius]="'5px'" [shape]="'square'" [width]="'100%'" [height]="'100%'"></ngx-shimmer-loading>
        </div>

        <div style="height: 50px; margin-bottom: 10px;">
            <ngx-shimmer-loading [borderRadius]="'5px'" [shape]="'square'" [width]="'100%'" [height]="'100%'"></ngx-shimmer-loading>
        </div>

        <div style="height: 50px; margin-bottom: 10px;">
            <ngx-shimmer-loading [borderRadius]="'5px'" [shape]="'square'" [width]="'100%'" [height]="'100%'"></ngx-shimmer-loading>
        </div>
    </div>
</div>