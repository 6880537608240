<button style="margin: 10px;" class="btn btn-default" (click)="onReturnButtonClicked()">
    <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" fill="black" class="bi bi-arrow-left" viewBox="0 0 16 16">
        <path fill-rule="evenodd" d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8z"/>
    </svg>
</button>
<div class="container" style="height: 86vh">
    <div class="row h-100 align-items-center">
        <div class="col-sm-auto mx-auto">
            <img class="rounded mb-3 img-fluid mx-auto d-block" src="/assets/img/not-found-meme.jpg" width="400px">
            <h1 class="text-center mb-3">Irmão, você não pode navegar assim</h1>
        </div>
    </div>
</div>
