import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-loading-user',
  templateUrl: './loading-user.component.html'
})
export class LoadingUserComponent{

  constructor() { }
}
